import { useLocalStorage } from "./useLocalStorage"
import { useEffect } from "react"

export function useDarkMode() {
  // Use our useLocalStorage hook to persist state through a page refresh.
  // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
  // @ts-ignore
  const [enabledState, setEnabledState] = useLocalStorage("dark-mode-enabled")

  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const enabled = typeof enabledState !== "undefined" ? enabledState : false

  // Fire off effect that add/removes dark mode class
  useEffect(
    () => {
      const className = "dark"
      if (typeof window !== "undefined") {
        const element = window.document.documentElement
        if (enabled) {
          element.classList.add(className)
        } else {
          element.classList.remove(className)
        }
      }
    },
    [enabled] // Only re-call effect when value changes
  )

  // Return enabled state and setter
  return [enabled, setEnabledState]
}
