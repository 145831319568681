import React, { FC } from "react"
import styled, { createGlobalStyle } from "styled-components"
import LanguagePicker from "./LanguagePicker"
import { useDarkMode } from "../hooks/useDarkMode"
import { AnimatePresence } from "framer-motion"
import { FaMoon, FaSun } from "react-icons/fa"

const GlobalStyle = createGlobalStyle`

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
  scroll-behavior: smooth;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-weight: normal;
  line-height: 1.6;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
/* -------------------------------------------------Reset End-------------------------------------------------------------- */

:root {
  --s1: 0.25rem;
  --s2: 0.5rem;
  --s3: 0.75rem;
  --s4: 1rem;
  --s5: 1.25rem;
  --s6: 1.5rem;
  --s8: 2rem;
  --s10: 2.5rem;
  --s12: 3rem;
  --s16: 4rem;
  --s20: 5rem;
  --s24: 6rem;
  --s32: 8rem;
  --s40: 10rem;
  --s48: 12rem;
  --s56: 14rem;
  --s64: 16rem;
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  /* --primary: #4e5180; */
  --primary: #584e80;
  --primary-dark: #2f243a;
  --white: white;
  --off-white: #f9f9f9;
  --black: #1b1b1b;
  --green: #915f6d;
  --red: #5a4262;
  --orange: #49796b;
  --light-grey: #e4e4e4;
  --light-gray: var(--light-grey);
  --grey: #797979;
  --gray: var(--grey);
  --border-radius: 15px;
  --sidebar-width: var(--s32);
  &.dark {
    --white: #1b1b1b;
    --black: #e4e4e4;
    --grey: #e4e4e4;
    --off-white: #202124;

  }
}

* {
  outline-color: var(--primary-dark);
}

body {
  max-width: 2500px;
}
`

const Icons = styled.div`
  position: absolute;
  top: var(--s2);
  right: var(--s2);
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    top: var(--s8);
  }
`

const DarkModeButton = styled.button`
  background: none;
  border: none;
  margin-right: var(--s6);
  cursor: pointer;
`

const Layout: FC = ({ children }) => {
  const [isDark, setIsDark] = useDarkMode()

  return (
    <>
      <GlobalStyle />
      <Icons>
        <DarkModeButton onClick={() => setIsDark(!isDark)}>
          <AnimatePresence>
            {!isDark ? <FaMoon size="25" color="#797979" /> : <FaSun size="25" color="#e4e4e4" />}
          </AnimatePresence>
        </DarkModeButton>
        {/* <LanguagePicker /> */}
      </Icons>
      {children}
    </>
  )
}

export default Layout
